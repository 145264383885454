// Central Settings

$space: 16;
$columns: 6;
$unit: 1px * $space;
$block: $unit * $space;
$box-sizing: border-box;
$class-dark: s-dark;
$fs-ratio: $major-third;

// BROWSER SETTINGS

// Use of Safe Area
$safe-area: false;

// SITE SIZE

$is-site-responsive: true !default;
$is-site-fluid: true !default;
$fixed-width: $block * $columns;
$fluid-width: 100%;

// Overarching settings

// ASPECT RATIO

$aspect-ratio: (
    landscape: 75%,
    square: 100%,
    portrait: 400%/3,
    widescreen: 56.25%,
);

// BASE SPACING

$rem-units: true !default;
$vertical-space: $unit !default;
$touch-target: $unit * 2.5 !default;

// Padding
$padding: $unit !default;
$padding-ratio: 1.5 !default;
$margin: $unit !default;
$margin-ratio: 2 !default;

// Radius
$radius: $unit / 3;

// Borders
$border-width: 1px;

// SCREEN SIZE

$screen-size: (
    s: em($fixed-width/4, round),
    m: em($fixed-width/2, round),
    l: em($fixed-width, round),
    xl: em($fixed-width * 1.5, round),
    sh: em(360px, round),
    mh: em(500px, round),
);

// SITE DIRECTION

$direction: ltr !default;
$start: left !default;
$end: right !default;

// SIZE RATIO

$size-ratio: (
    small: 1,
    medium: 1,
);

// TIMINGS

$smoothness: 0.2;

// Animations
$duration: 1s;
$curve: linear;

// Z-INDEX

$zindex: (
    top: 1000,
    middle: 100,
    bottom: 1,
    base: 0,
    below: -1,
);

// Base Colors

$fade: 0.5;
$black: #2b2f32;
$white: #ffffff;
$red: #f40006;
$orange: #fc3823;
$yellow: #e7c270;
$green: #4ecd6e;
$cyan: #66cceb;
$blue: #34a2f3;
$navy: #5f6776;
$purple: #5f27cd;
$magenta: #f368e0;
$grey: #dbdbdb;
$off-white: #fafbfc;

$error: $red;
$warning: $yellow;
$success: $green;

$brand-color-1: $red;
$brand-color-2: $black;
$brand-color-3: $off-white;

$foreground-color: $brand-color-1;
$background-color: $off-white;

$white-text: color-white;

$grad-0-dark: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
$grad-0-dark-x2: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
$grad-0-dark-ltr: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
$grad-light-0: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
$grad-light-0-x2: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
$grad-light-0-ltr: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));

$shade-amount: 0.1;
$tint-amount: 0.6;

$shade: rgba($black, $shade-amount);
$tint: rgba($white, $tint-amount);

// Link Light Color
$link-light-color: color-link-alt;

// Dark Mode
$enable-dark-mode: false;
$add-dark-bg-link-elements: false;

// Fill Utility Settings
$smart-background-fill: true;

// Typography

$base-line-height: $unit * 1.5 !default;
$base-font-size: $unit;
$font-features: liga kern;
$line-height-ratio: $base-line-height / $base-font-size !default;

// Font Weight and Style

$bold: 600;
$bold-font-family: "Brandon Text W01 Bold";

$italic: italic !default;
$italic-font-family: inherit !default;

$bold-italic-font-family: inherit !default;

$lighter: lighter !default;

// Hyphenate Browser Prefixes
$hyphenate: false;
$hyphenate-limit-chars: 6 3 3;
$hyphenate-limit-lines: 2;
$hyphenate-limit-last: always;
$hyphenate-limit-zone: 8%;
$hyphen-prefixes: webkit ms spec;

// System Fonts
$system-fonts-serif: Georgia, Times, "Times New Roman", serif;
$system-fonts-sans: "Brandon Text W01 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$system-font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;

// Default Fonts
$icon-font: "icomoon", sans-serif;
$base-font-family: $system-fonts-sans;

// Mono Fonts
$mono-font-family: $system-font-mono;
$mono-font-size: 0.95em;

// Links
$link-weight: normal;
$link-visited-opacity: 0.8;

// Weights / Styles
$bold: bold;
$italic: italic;

// Lists
$dt-weight: $bold;

// Quotes
$english-quotes: true !default;
$open-quote: null;
$close-quote: null;

// Headings
$heading-font-family: inherit;
$heading-weight: $bold;
$heading-color: $green;

$letter-spacing-unit: 0.1em;

$h1-size: delta;
$h1-size-m: false;
$h1-size-l: zeta;
$h1-weight: false;
$h1-lh: false;
$h1-lh-m: false;
$h1-lh-l: false;

$h2-size: gamma;
$h2-size-m: false;
$h2-size-l: delta;
$h2-weight: normal;
$h2-lh: false;
$h3-size-l: false;
$h3-weight: false;
$h3-lh: false;
$h3-lh-m: false;
$h3-lh-l: false;

$h2-lh-m: false;
$h2-lh-l: false;

$h3-size: beta;
$h3-size-m: false;
$h4-size: alpha;
$h4-size-m: false;
$h4-size-l: false;
$h4-weight: false;
$h4-lh: false;
$h4-lh-m: false;
$h4-lh-l: false;

$h5-size: false;
$h5-size-m: false;
$h5-size-l: false;
$h5-weight: false;
$h5-lh: false;
$h5-lh-m: false;
$h5-lh-l: false;

$h6-size: false;
$h6-size-m: false;
$h6-size-l: false;
$h6-weight: false;
$h6-lh: false;
$h6-lh-m: false;
$h6-lh-l: false;

// Site Template

// Overall GridLayout Box and App Box settings
$grid-mqs: orig m l; // orig s m l xl

// GridLayout Box settings

$grid-columns: 6;
// Set additional column amounts
$grid-col-x: 1 2 3 4 6;
$grid-col-x-fr: false;

$grid-column-gap: 1rem;
// Set additional column gaps
$grid-gap-x: 0 2;

// App Box settings

$app-column-gap: $grid-column-gap;
// Set additional column gaps
$app-gap-x: $grid-gap-x;
// Set media queries
$app-mqs: $grid-mqs;
$app-gap-mqs: $app-mqs;

// App WrapLayout
$wrap-width: $fixed-width;

// Site layout settings
$min-body-height: $unit * 36;

// Decoration

// BORDERS
$border-width: 1px;
$border-color: $grey;

// SHADOWS
$shadow-reset: 0 0 0 rgba(0, 0, 0, 0);
$shadow-light: 0 1px 4px rgba(0, 0, 0, 0.1);
$shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
$shadow-medium: 0 2px 10px rgba(0, 0, 0, 0.2);
$shadow-heavy: 0 3px 16px rgba(0, 0, 0, 0.3);
$shadow-ambient: 0px 10px 24px 10px rgba(118, 127, 144, 0.1);

$shadow-light-inset: inset $shadow-light;
$shadow-medium-inset: inset $shadow-medium;

$shadow-lighten: inset 0 0 0 20rem rgba($white, 0.25);
$shadow-lighten-x2: inset 0 0 0 20rem rgba($white, 0.5);
$shadow-darken: inset 0 0 0 20rem rgba($black, 0.1);
$shadow-darken-x2: inset 0 0 0 20rem rgba($black, 0.2);

$base-shadow: $shadow;
$base-shadow-inset: inset $shadow;

$emboss-tint: rgba($white, $tint-amount);
$emboss-shade: rgba($black, $shade-amount * 1.5);
$emboss-offset: $unit * 0.25;
$emboss-size: $unit * 0.5;

// UI Items

// Article Main Properties
$article-main-spacing: $unit;
$article-main-spacing-unit: rem;

// Article Heading
$article-heading-spacing: $unit;
$article-heading-spacing-unit: rem;

// Alert
$alert-padding: $padding/2 $padding;
$has-alert-border: true;

// Breadcrumb
$breadcrumb-separator: "›";

// BUTTONS

// Button Height
$button-height: $touch-target;
$button-height-md: $unit * 2;
$large-button-height: $touch-target * 1.5;

// Button Padding
$button-padding: $unit;
$button-padding-md: $unit * 0.75;

// Button Border
$button-border-width: em($border-width) !default;
$button-border-color: rgba($black, $shade-amount);
$button-border: 0;

// Button Radius
$button-radius: em($radius); // 0 | value

// Button Color
$button-bg-color: darken($white, 10%);
$button-gradient: none !default;
$button-gradient-hover: none !default;

$button-1-gradient: none;
$button-1-gradient-hover: none;

// Button typography
$button-font-family: inherit;
$button-font-weight: normal;
$button-font-size-ratio: 1;
$button-letter-spacing: 0;
$button-text-transform: none;
$button-font-settings: default;

// Button Weight
$button-weight: normal;
$button-1-weight: normal;

// Button Shadows
$button-shadow: $shadow-reset !default;
$button-shadow-hover: $button-shadow !default;
$button-shadow-active: inset $shadow !default;
$button-adjust-hover: $shadow-lighten;
$button-adjust-active: $shadow-darken;

$button-1-shadow: $shadow;
$button-1-shadow-hover: $button-shadow-hover;
$button-1-shadow-active: $button-shadow-active;
$button-1-adjust-hover: $button-adjust-hover;
$button-1-adjust-active: $button-adjust-active;

// Hollow button
$button-hollow-border-width: 1px;

// CHECKLIST

$checklist-item-spacing: em($unit * 0.5);

$checklist-box-size: em($unit * 1.5);
$checklist-box-spacing: em($unit * 2.5);
$checklist-box-border-width: em(2px);

$checklist-tick-width: $checklist-box-size * 0.25;
$checklist-tick-height: $checklist-box-size * 0.45;
$checklist-tick-border-width: $checklist-box-border-width;
$checklist-tick-radius: $radius;
$checklist-tick-transform: rotate(45deg) translate(120%, 10%);

$checklist-radio-size: $checklist-box-size * 0.5;
$checklist-radio-radius: $checklist-box-size;

$checklist-base-color: currentColor;
$checklist-hi-color: $foreground-color;

$checklist-radio-radius: $checklist-box-size;

// TICKBOXES

$checklist-tick-static-marker-color: $checklist-hi-color;
$checklist-tick-static-border-marker-color: $checklist-hi-color;
$checklist-tick-static-bg-color: transparent;
$checklist-tick-static-opacity: 1;
$checklist-tick-static-hover: false;

$checklist-tick-unchecked-marker-color: transparent;
$checklist-tick-unchecked-border-marker-color: $checklist-base-color;
$checklist-tick-unchecked-bg-color: transparent;
$checklist-tick-unchecked-opacity: $fade * 0.5;
$checklist-tick-unchecked-hover: true;

$checklist-tick-checked-marker-color: $white;
$checklist-tick-checked-border-marker-color: $checklist-hi-color;
$checklist-tick-checked-bg-color: $checklist-hi-color;
$checklist-tick-checked-opacity: 1;
$checklist-tick-checked-hover: false;

// RADIO BUTTONS

$checklist-radio-static-marker-color: $checklist-hi-color;
$checklist-radio-static-border-marker-color: $checklist-hi-color;
$checklist-radio-static-bg-color: transparent;
$checklist-radio-static-opacity: 1;
$checklist-radio-static-hover: false;

$checklist-radio-unchecked-marker-color: transparent;
$checklist-radio-unchecked-border-marker-color: $checklist-base-color;
$checklist-radio-unchecked-bg-color: transparent;
$checklist-radio-unchecked-opacity: $fade * 0.5;
$checklist-radio-unchecked-hover: true;

$checklist-radio-checked-marker-color: $white;
$checklist-radio-checked-border-marker-color: $checklist-hi-color;
$checklist-radio-checked-bg-color: $checklist-hi-color;
$checklist-radio-checked-opacity: 1;
$checklist-radio-checked-hover: false;

// DIALOG

// Dialog box
$dialog-background: dialog-bg !default;
$dialog-color: dialog-text !default;
$dialog-padding-default: dialog-padding;
$dialog-radius-default: $radius * 2 !default;
$dialog-shadow-default: $shadow-heavy !default;
$dialog-border-default: 0 !default;
$dialog-width-default: $unit * 30;

// Inner Parts of the dialog box
$dialog-inner-gaps: $unit;
$dialog-inner-padding: 0;
$dialog-head-padding: $dialog-inner-padding;
$dialog-body-padding: $dialog-inner-padding * 0.5 $dialog-inner-padding;
$dialog-foot-padding: $dialog-inner-padding;

// Dialog Head
$dialog-head-bg: transparent;
$dialog-head-spacing: $unit;

// Dialog Foot
$dialog-foot-bg: transparent;

// Icon size
$dialog-icon-size: beta;
$dialog-icon-size-md: gamma;

// LABELS
$label-weight: normal;
// Inside fieldset
$label-weight-in-fieldset: normal;

// Extra Label
$label-note-weight: normal;
$label-note-opacity: $fade;

// Checkbox
$label-check-weight: normal;

$inline-label-width: $block * 0.5;

// FIELDS
// Field Height
$input-height: $touch-target;
$input-height-md: $unit;

// Field Padding
$field-padding: 0 $unit * 0.25;
$field-padding-md: $field-padding;

$textarea-padding: $unit * 0.5 !default;

// Field Border
$field-border-width: 1px !default;
$field-border-color: $border-color;
$field-border: inset 0 0 0 $field-border-width $field-border-color;
$field-border-error: inset 0 0 0 $field-border-width var(--color-neg);
$field-border-warn: inset 0 0 0 $field-border-width var(--color-warn);
$field-border-success: inset 0 0 0 $field-border-width var(--color-pos);

// Field Radius
$field-radius: $radius;

// Field Color
$field-bg: $white;
$field-color: var(--color-text);
$field-font-family: inherit;

// Field Shadows
$field-shadow: $field-border;

// Select
$select-triangle-size: 9px 6px;

// Help Text
$field-help-font-size: milli;

// Feedback Text
$field-feedback-font-size: $field-help-font-size;

// FORM LAYOUTS
$block-form-gap: $unit;
$inline-form-gap: $unit;

// IMAGES
$avatar-size: $unit * 3;
$avatar-radius: 1000px;
$avatar-shadow: $shadow;

// LOADER
$loader-width: 1em;
$loader-color: $foreground-color;
$loader-stroke-width: 0.15em;
$loader-speed: $duration;

// METER
$meter-gradient: none;

// PAGER
// Pager Item
$pager-bg: rgba($border-color, 0.1);
$pager-color: $brand-color-1;
$pager-padding: n $padding * 0.5;
$pager-padding-mw: n $padding * 0.75;
$pager-weight: normal;
$pager-height: $touch-target;
$pager-height-md: $unit * 2;
$pager-min-width: $touch-target;
$pager-min-width-mw: 0;
$pager-border: none;

// Selected Item
$pager-selected-bg: $brand-color-1;
$pager-selected-color: $white;
$pager-selected-border: none;
$pager-selected-weight: $pager-weight;

// Hover state on Item (not selected)
$pager-hover-bg: darken($foreground-color, 10%);
$pager-hover-color: $white;
$pager-hover-border: inset 0 0 0 $border-width darken($foreground-color, 10%);

// Active state on Item (not selected)
$pager-active-bg: none;
$pager-active-color: var(--color-1);
$pager-active-border: inset 0 0 0 $border-width currentColor;

// PILLS
$pill-height: em($unit * 2);
$pill-radius: $radius/2;
$pill-padding-x: em($unit);
$pill-border-width: 1px;

// Pill font
$pill-font-size: milli;
$pill-font-weight: bold;

// Pill Border
$pill-border-width: em($border-width) !default;
$pill-border-color: rgba($black, 0);
$pill-border: none;

// Pill Shadows
$pill-shadow: $shadow-reset;
$pill-adjust-hover: $shadow-lighten;
$pill-adjust-active: $shadow-darken;

// Between Pills
$pill-gap: em($unit * 0.5);

// SWITCH
$switch-size: $unit * 1.5;
$switch-padding: $border-width;
$switch-background: rgba($border-color, $shade-amount);
$switch-color-off: $border-color;
$switch-color-on: $brand-color-1;
$switch-speed: $duration * $smoothness;

// TABLES

// Table Cells
$table-cell-padding-x: $unit * 0.5;
$table-cell-padding-y: $unit * 0.5;

// Table Rows
$table-row-odd: $background-color;
$table-row-even: rgba($white, $tint-amount);

// Table Borders
$table-border-width: 1px;
$table-border-color: $border-color;

$table-head-border-color: $table-border-color;

// Table Row Hover States
$table-row-hover-color: rgba($border-color, $shade-amount/2);
$table-row-select-color: var(--color-link);
$table-hover-pointer: true;

// TABS
$tab-gap: 0;
$tab-padding: $unit $unit;
$tab-current-fill: var(--tab-current);
$tab-content-padding: $tab-padding;
$tab-content-fill: var(--color-off-white);

// TOOLTIP
$arrow-size: $unit * 0.5;
$arrow-pos: $arrow-size + $unit * 0.25;

$tooltip-padding: $unit * 0.25 $unit * 0.5;
$tooltip-radius: $button-radius;

// VARS

$var-theme: (
    unit: $unit,
    // Colors
    color-shade: rgba($grey, $shade-amount),
    color-shade-x2: rgba($grey, $shade-amount * 2),
    color-off-white: $off-white,
    color-white: $white,
    color-site: $background-color,
    color-light: $background-color,
    color-dark: var(--color-text),
    color-navy: $navy,
    color-pos: $success,
    color-neg: $error,
    color-warn: $warning,
    color-red: $red,
    color-orange: $orange,
    color-yellow: $yellow,
    color-green: $green,
    color-cyan: $cyan,
    color-blue: $blue,
    color-purple: $purple,
    color-magenta: $magenta,
    color-1: $brand-color-1,
    color-2: $brand-color-2,
    color-3: $brand-color-3,
    color-text: $black,
    color-text-alt: rgba($black, 0.5),
    color-link: $brand-color-1,
    color-link-alt: lighten($brand-color-1, 20%),
    color-link-default: $brand-color-1,
    // Borders
    border-color: $border-color,
    // Buttons
    button-bg: $foreground-color,
    button-text: $white,
    button-1: var(--color-2),
    button-2: var(--color-1),
    // Dialog
    dialog-padding: $unit,
    dialog-bg: $white,
    dialog-text: var(--color-text),
    // Fields
    field-bg: $field-bg,
    field-border: $field-border,
    field-background-selected: $blue,
    field-color: $field-color,
    field-shadow: $field-shadow,
    field-border-error: $field-border-error,
    field-border-warn: $field-border-warn,
    field-border-success: $field-border-success,
    field-label-error: inherit,
    field-label-warn: inherit,
    field-label-success: inherit,
    // Modal
    backdrop-color: rgba($black, $fade * 1.5),
    modal-padding: $unit,
    // Site Header
    header-bg: $brand-color-2,
    header-color: $white,
    // Tables
    cell-sortable: var(--color-link),
    // Tabs
    tab-current: rgba($shade, $shade-amount * 3),
    // Tooltips
    tooltip-bg: $foreground-color,
    tooltip-color: $background-color,
);

$var-theme-sm: ();
$var-theme-md: (
    modal-padding: $unit * 2,
);
$var-theme-lg: (
    dialog-padding: $unit * 1.5,
    modal-padding: $unit * 4,
);

$var-theme-dark: (
    color-shade: rgba($white, $shade-amount),
    color-shade-x2: rgba($white, $shade-amount * 2),
    color-site: $foreground-color,
    color-text: $white,
    color-link: var(--color-link-alt),
    dialog-bg: $foreground-color,
);

$var-theme-light: ();
