@import "utilities/all";

@import "alert";
@import "breadcrumb";
@import "button";
@import "calendar";
@import "checklist";
@import "dialog";
@import "divide";
@import "dropdown";
@import "footer";
@import "react-calendar";
@import "select";
@import "timer";

.c-title {
    @media only screen and (max-width: 765px) {
        display: none;
    }
}
