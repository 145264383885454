.c-checklist {
    border: 1px solid var(--border-color);
    border-radius: $radius * 2;
    overflow: hidden;

    label {
        margin: 0px !important;
        padding: $padding;
        background: var(--color-white);

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--border-color);
        }
    }

    &.\+right {
        span {
            padding-left: 0 !important;
            padding-right: $padding * 2;
        }
        input[type="radio"] {
            right: 0 !important;
            left: inherit !important;

            &:checked ~ span:before {
                right: 0 !important;
                left: inherit !important;
            }

            ~ span {
                &:before {
                    right: 0 !important;
                    left: inherit !important;
                }

                &:after {
                    right: 0;
                    left: inherit !important;
                }
            }
        }
    }

    &.\+center {
        display: flex;
        justify-content: space-between;

        label {
            border-bottom: none;
            width: 50%;

            &:not(:last-of-type) {
                border-right: 1px solid var(--border-color);
            }
        }

        input[type="checkbox"] ~ span {
            padding-left: 0;

            &:before {
                left: 50% !important;
                transform: translateX(-50%);
            }

            &:after {
                left: 42% !important;
            }
        }

        input[type="checkbox"]:checked ~ span {
            padding-left: 0 !important;
        }
    }
}

.c-checklist input[type="checkbox"],
.c-checklist input[type="radio"] {
    display: none;
}
