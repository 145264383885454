.u-marg-bottom-small {
    margin-bottom: $margin/2;
}

.u-marg-top-small {
    margin-top: $margin/2;
}

.u-marg-right-small {
    margin-right: $margin/2;
}
