.c-footer {
    .c-button.\+primary {
        flex-grow: 1;
    }
    @media only screen and (min-width: 765px) {
        justify-content: flex-end;

        .c-button.\+primary {
            flex-grow: 0;
        }
    }
}
