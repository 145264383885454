.c-button {
    font-family: Brandon Text W01 Bold !important;
    font-size: $unit - 1 !important;

    &.\+quiet {
        color: var(--color-text) !important;
    }

    &.\+primary {
        padding-left: $padding * 2;
        padding-right: $padding * 2;
    }

    &.\+link {
        font-family: "Brandon Text W01 Regular" !important;
        text-decoration: underline;
    }
}
