@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=88fd7fc3-d107-44d3-8dcf-240cdfa7e825");

@font-face {
    font-family: "Brandon Text W01 Regular";
    src: url("fonts/03bbca7e-869a-4f4f-a010-39e78be0b5df.woff2") format("woff2"),
        url("fonts/b3634ffb-e593-4817-9d09-eaf133a014d0.woff") format("woff");
}

@font-face {
    font-family: "Brandon Text W01 Medium";
    src: url("fonts/12cd8b0d-0f5c-4ec2-b2c7-9b30a8d06ded.woff2") format("woff2"),
        url("fonts/008df1be-c6b5-4688-a068-6729f08a0b08.woff") format("woff");
}

@font-face {
    font-family: "Brandon Text W01 Bold";
    src: url("fonts/314148f2-1786-479f-9e0b-e9418b8fee19.woff2") format("woff2"),
        url("fonts/04c8b798-e77b-4516-98e8-964145344d65.woff") format("woff");
}
