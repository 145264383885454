$breadcrumb-count-size: $unit * 1.25;

.c-breadcrumb > * + * {
	margin-left:$margin !important;

	&:before {
		margin-right:$margin !important;
	}
}

.c-breadcrumb {

	display: flex;
	justify-content: center;

	li {
		opacity:0.3;

		&.\+current {
			opacity: 1;
		}
	}
}

.c-breadcrumb__link-name {
	@media only screen and (max-width: 765px) {
		display:none;
	}
}

.c-breadcrumb__count {
	border-radius:$radius * 10;
	background:var(--color-1);
	color:var(--color-white);
	font-family: "Brandon Text W01 Bold";
	padding:$padding/4;
	width: $breadcrumb-count-size;
	height: $breadcrumb-count-size;
	display: inline-block;
	text-align: center;
	font-size: 12px;
	line-height: 11px;
}
