.c-divide {
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-align: center;
    color: $border-color;

    span {
        padding: 0 $padding;
    }
}

.c-divide:before,
.c-divide:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: "\a0";
    background-color: $border-color;
}

.c-divide:before {
    margin-left: -50%;
    text-align: right;
}
