.c-dialog {
    padding: $padding * 2 !important;

    @media only screen and (min-width: 765px) {
        display: block;
        padding: $padding;
    }

    &.\+wide {
        width: 700px !important;
    }
}

.c-dialog__heading {
    font-family: "Brandon Text W01 Bold";
    font-weight: normal !important;
    font-size: $unit + 4 !important;
}
