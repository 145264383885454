.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    font-family: $base-font-family;
    line-height: 1.125em;
    border: 1px solid var(--border-color);
    border-radius: $radius * 2;
    box-shadow: 0px 10px 24px 10px rgba(118, 127, 144, 0.1);
    overflow: auto;
}

.react-calendar__tile {
    position: relative;

    &.has-notification {
        &:before {
            content: "";
            position: absolute;
            top: 9px;
            right: $unit;
            width: 8px;
            height: 8px;
            background: var(--color-pos);
            border-radius: $unit * 20;
        }
    }

    &.is-active {
        background: rgba($black, 0.1);
        border-radius: $radius * 2;
    }
}

.react-calendar__tile:enabled:hover {
    color: var(--color-text);
    background: rgba($black, 0.1);
    border-radius: $radius * 2;
    cursor: pointer;
    box-shadow: 0px 10px 24px 10px rgba(118, 127, 144, 0.1);
}

.react-calendar__navigation {
    margin-bottom: 0;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--color-2);
}

.react-calendar__month-view__days__day--weekend {
    color: var(--color-2);
}

.react-calendar__tile:disabled {
    color: var(--color-2);
    background: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.react-calendar__navigation button[disabled] {
    opacity: 0.5;
    background: none;
}

.react-calendar__month-view__weekdays__weekday {
    text-align: center;
    padding: $padding 0;
    color: var(--color-1);
    font-family: "Brandon Text W01 Bold";
}

.react-calendar__tile.react-calendar__month-view__days__day {
    padding: $padding;
    font-size: $unit;
}

.react-calendar__navigation__label {
    font-size: $unit + 4;
    font-family: "Brandon Text W01 Bold";
}
.react-calendar__navigation {
    border-bottom: 1px solid var(--border-color);
    padding: $padding;
    background: var(--color-site);
}

.react-calendar__viewContainer {
    padding: $padding;
}

.react-calendar__navigation__arrow {
    background: var(--color-shade);
    padding: $padding/2;
    border-radius: $radius;
    font-size: $unit + 4;

    &:hover {
        cursor: pointer;
        background: rgba($black, 0.1);
    }
}
