$calendar-day-width: 55px;
$calendar-load-button-height: 72px;

.c-calendar {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    display: none;

    @media only screen and (max-width: 765px) {
        display: block;
        padding: $padding 0;
    }
}

.c-calendar__item {
    text-align: center;
    text-transform: uppercase;
    line-height: $unit * 1.5;
    color: var(--brand-color-1);
    width: $calendar-day-width;
    position: relative;

    &.active {
        color: var(--brand-color-1);

        .c-calendar__day,
        .c-calendar__month {
            opacity: 1;
        }
    }

    &.has-notification {
        &:before {
            content: "";
            position: absolute;
            top: 1px;
            right: $unit/2;
            width: 10px;
            height: 10px;
            background: $green;
            border: 2px solid var(--color-site);
            border-radius: $unit * 20;
        }
    }

    &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &.is-weekend {
        opacity: 0.3;
    }
}

.c-calendar__day,
.c-calendar__month {
    font-weight: 600;
    font-size: $unit - 4;
    margin: 0;
}

.c-calendar__day {
    opacity: 0.5;
}

.c-calendar__date {
    font-size: $unit + 10;
    margin: 0;
}

.c-calendar__month {
    opacity: 0.35;
}

.c-calendar__load-button {
    width: $calendar-day-width;
    height: $calendar-load-button-height;
    line-height: $calendar-load-button-height;
    font-size: $unit - 4;
    text-transform: uppercase;
    font-weight: 600;
    background: none;
    border: none;

    &.\+previous {
        border-right: 1px solid var(--brand-color-1);

        &:before {
            content: "‹";
            margin-right: $unit/4;
            font-size: $unit;
        }
    }

    &.\+next {
        border-left: 1px solid var(--brand-color-1);

        &:after {
            content: "›";
            margin-left: $unit/4;
            font-size: $unit;
        }
    }
}

.c-calendar__sticky {
    position: sticky;
    top: 0;
    z-index:10;

    @media only screen and (min-width: 765px) {
        padding-top: $padding;
    }
}
