$timer-size: $unit * 3.75;

.base-timer {
    width: $timer-size;
    height: $timer-size;
    position: relative;
}

.base-timer_svg {
    transform: scaleX(-1);
}

.base-timer_circle {
    fill: none;
    stroke: none;
}

.base-timer_path-elapsed {
    stroke-width: $unit/4;
    stroke: var(--border-color);
}

.base-timer_path-remaining {
    stroke-width: $unit/4;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
}

.base-timer_path-remaining.green {
    color: var(--color-pos);
}

.base-timer_path-remaining.orange {
    color: var(--color-warn);
}

.base-timer_path-remaining.red {
    color: var(--color-neg);
}

.base-timer_label {
    position: absolute;
    width: $timer-size;
    height: $timer-size;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $unit - 2;
    font-family: "Brandon Text W01 Bold";
}
